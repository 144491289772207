*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: #191b20;
  color: #c8d6e5;
  box-sizing: border-box;
  font-family: sans-serif;
  font-weight: 400;
}

@font-face {
  font-family: 'dafoe';
  src: url('/fonts/dafoe.ttf');
}

@font-face {
  font-family: 'antonio';
  src: url('/fonts/antonio.ttf');
}

@font-face {
  font-family: 'futura';
  src: url('/fonts/futura.ttf');
}

@font-face {
  font-family: 'roboto';
  src: url('/fonts/roboto.ttf');
}

@font-face {
  font-family: 'din';
  src: url('/fonts/din.otf');
}

@font-face {
  font-family: 'tech';
  src: url('/fonts/tech.ttf');
}
